'use client';
import React, { useCallback, useState } from 'react';
import { Field, FieldGroup } from '@purposeinplay/core-v2';
import { usePathname, useSearchParams } from 'next/navigation';
import { useTranslation } from '@/app/i18n/client';
import { useRegister } from '@/hooks/auth';
import { AuthContentType } from '@/types/json-content/auth';
import { cn } from '@/utils/style';
import useModal from '@/hooks/modal';
import { useQueryClient } from '@tanstack/react-query';
import SocialAuth from './components/social-auth';
import SignUpFormFields from './components/signup/form-fields';
import SignUpFooter from './components/signup/footer';
import SignUpFormHeader from './components/signup/form-header';
import RestrictedMessage from './components/restricted-message';

interface Props {
  currency?: any;
  bonus?: string;
  amount?: string;
  openLoginModal?: any;
  containerStyles?: string;
  quick?: boolean;
  isStandalone?: boolean;
}

export type StateType = {
  email: string | undefined;
  password?: string | undefined;
  terms_acceptance: boolean;
};

const initialState: StateType = {
  email: '',
  password: '',
  terms_acceptance: false,
};

const RegisterForm: React.FC<Props> = React.memo(
  ({
    amount,
    bonus,
    openLoginModal,
    quick = false,
    containerStyles,
    isStandalone = false,
  }) => {
    const { close, open: openModal } = useModal();
    const { t } = useTranslation();
    const authContent = t('auth') as AuthContentType;
    const content = authContent.signup_modal;

    const [isPasswordValid, setIsPasswordValid] = useState(false);

    const searchParams = useSearchParams();
    const pathname = usePathname();
    const inOverlay = searchParams?.get('overlay');

    const queryClient = useQueryClient();
    const [state, setState] = useState(initialState);
    const { mutateAsync: register, isPending, isError, error } = useRegister();

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const target = e.target;
        setState({ ...state, [type]: target.value });
      },
      [state],
    );
    const handleRegister = useCallback(
      async (e: any) => {
        e.preventDefault();
        if (amount && bonus) {
          localStorage.setItem(
            'awaitingDeposit',
            JSON.stringify({ currency: 'BTC', amount, bonus }),
          );
        }
        try {
          await register({
            user: {
              email: state.email,
              password: state.password,
              terms_acceptance: state.terms_acceptance,
              receive_promos: true,
            },
            currency: 'BTC',
            isStandalone,
          });
          if (isStandalone) {
            window.parent.postMessage({ type: 'refresh_user' }, '*');
            window.parent.postMessage({ type: 'close' }, '*');
          }
          queryClient.invalidateQueries({ queryKey: ['currentUser'] });
          close();
          openModal('bonus-selection');
          if (pathname === '/signup') {
            window.location.assign('/');
          }
        } catch (e) {
          console.error('Register error:', e);
        }
      },
      [
        amount,
        bonus,
        close,
        isStandalone,
        openModal,
        pathname,
        queryClient,
        register,
        state,
      ],
    );
    const isButtonDisabled =
      isPending || !isPasswordValid || !state?.email?.length;
    return (
      <div className={cn(['relative z-50 h-full', containerStyles])}>
        <form
          className="flex h-full flex-col justify-between"
          onSubmit={handleRegister}
        >
          <FieldGroup>
            <Field>
              <SignUpFormHeader
                quick={quick}
                content={content}
                inOverlay={inOverlay}
              />
            </Field>
            <SocialAuth />
            <SignUpFormFields
              quick={quick}
              content={content}
              isError={isError}
              errors={error}
              state={state}
              setIsPasswordValid={setIsPasswordValid}
              handleChange={handleChange}
            />
            {isError &&
              error?.reason &&
              error?.reason === 'registration_restricted_in_country' && (
                <RestrictedMessage />
              )}
            <SignUpFooter
              isPending={isPending}
              error={error}
              isError={isError}
              isActionDisabled={isButtonDisabled}
              state={state}
              setState={setState}
              content={content}
              inOverlay={inOverlay}
              quick={quick}
              openLoginModal={openLoginModal}
            />
          </FieldGroup>
        </form>
      </div>
    );
  },
);

RegisterForm.displayName = 'RegisterForm';

export default RegisterForm;
